import {
    Box, Button, ButtonGroup, Container, FormControl, Grid,
    InputLabel, MenuItem, Paper, Select, TextField,
    Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InvalidAddressDialog from 'components/InvalidAddressDialog';
import message from 'components/Message';
import RecommendedAddress from 'components/RecommendedAddress';
import React, { PureComponent } from 'react';
import http from 'utils/http';
import BasketItemsView from './BasketItemsView';
import SingleAddressView from './SingleAddressView';

export default class BrokerView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            additionalReason: '',

            inputAddress: { shippingMethodCode: 'Standard', country: 'United States' },
            validating: false,
            submitting: false,
            contactInfo: null,
            showAddressCorrectedDialog: false,
            openInvalid: false,
            openRecommended: false,
            recommended: null,
            addressValidated: false,
            isPOBox: false
        };
    }

    componentDidMount() {
        const { contactInfo, inputAddress } = this.state;
        const { user, basketItems } = this.props;
        if (contactInfo === null) {
            if (user && user.customFields && user.customFields.npn) {
                this.setState({
                    contactInfo: {
                        line1: user.customFields.name,
                        line2: user.customFields.phone
                    }
                });
            } else if (basketItems && basketItems[0].customFields && basketItems[0].customFields.contactInfo) {
                this.setState({ contactInfo: basketItems[0].customFields.contactInfo });
            }
        }

        if (basketItems && basketItems[0].isCopy && basketItems[0].customFields.originalShippingAddress && !inputAddress.address) {
            const originalAddress = basketItems[0].customFields.originalShippingAddress;
            const newAddress = {
                ...inputAddress,
                address: originalAddress.Address,
                address2: originalAddress.Address2,
                city: originalAddress.City,
                state: originalAddress.State,
                zip: originalAddress.Zip,
                country: originalAddress.Country,
                firstName: originalAddress.FirstName,
                lastName: originalAddress.LastName,
                email: originalAddress.Email
            };

            this.setState({ inputAddress: newAddress });
        }
    }

    changeAdditionalReason = (e) => {
        const additionalReason = e.target.value;
        this.setState({ additionalReason });
    }

    onInputAddressChange = (inputAddress, addressValidated = false) => {
        this.setState({ inputAddress, addressValidated, isPOBox: false });
    }

    onReasonChange = (reason) => {
        this.setState({ reason });
    }

    onFieldChange = (e) => {
        const { contactInfo } = this.state;
        this.setState({
            contactInfo: {
                ...contactInfo,
                [e.target.name]: e.target.value
            }
        });
    };

    saveAddress = (inputAddress) => {
        this.setState({
            inputAddress, addressValidated: true, recommended: null, openRecommended: false, openInvalid: false, isPOBox: false
        });
        message.info(
            'The address has been updated, please re-Submit the order', 'bottom'
        );
    }

    getExceeded = () => {
        const {
            used, basketItems
        } = this.props;
        let exceededMonthlyLimit = false;
        if (basketItems && used) {
            for (const item of basketItems) {
                if ((used[item.code.toLowerCase()] || 0) + item.quantity > item.customFields.limit) {
                    exceededMonthlyLimit = true;
                }
            }
        }

        return exceededMonthlyLimit;
    }

    hasPOBox = (s) => {
        if (s) {
            const ps = s.replace(/\./g, '').toLowerCase().trim();

            return ps.startsWith('po ');
        }
        return false;
    };

    onSubmitOrder = () => {
        const { submitOrder } = this.props;
        const { inputAddress, contactInfo, addressValidated } = this.state;
        const fixedCI = {
            line1: contactInfo.line1 ? contactInfo.line1.replace(/|/g, ' ') : '',
            line2: contactInfo.line2 ? contactInfo.line2.replace(/|/g, ' ') : ''
        };

        if (this.hasPOBox(inputAddress.address) || this.hasPOBox(inputAddress.address2)) {
            this.setState({ isPOBox: true });

            return;
        }

        if (!addressValidated) {
            this.setState({ validating: true });
            http.post('store/validate-address', {
                Address1: inputAddress.address,
                Address2: inputAddress.address2,
                City: inputAddress.city,
                State: inputAddress.state,
                Zipcode: inputAddress.zip,
                Country: inputAddress.country || 'United States'
            }).then((r) => {
                if (r.Success) {
                    if (r.POBox || this.hasPOBox(inputAddress.address) || this.hasPOBox(inputAddress.address2)) {
                        this.setState({ isPOBox: true });

                        return;
                    }
                    this.setState({ isPOBox: false });

                    const addressR = {
                        ...inputAddress,
                        address: r.Address1,
                        address2: r.Address2,
                        city: r.City,
                        state: r.State,
                        zip: r.Zipcode
                    };
                    if (inputAddress.address !== r.Address1
                        || (!!inputAddress.address2 && !!r.Address2 && inputAddress.address2 !== r.Address2)
                        || inputAddress.city !== r.City
                        || inputAddress.state !== r.State
                        || inputAddress.zip !== r.Zipcode
                    ) {
                        const ia = { ...inputAddress };
                        if (!inputAddress.city && r.City) {
                            ia.city = r.City;
                            this.onInputAddressChange({
                                ...inputAddress, city: r.City
                            });
                        }
                        if (!inputAddress.state && r.State) {
                            ia.state = r.State;
                        }
                        this.onInputAddressChange(ia);

                        this.setState({ recommended: addressR, openRecommended: true });

                        return;
                    }

                    const { used } = this.props;
                    const exceeded = this.getExceeded();
                    const { reason: reasonType, additionalReason } = this.state;
                    const reason = reasonType === 'Other' ? additionalReason : reasonType;
                    submitOrder('', reason, used, inputAddress, fixedCI);
                } else if (r.StatusMessage === 'Insufficient Data') {
                    let msg = 'Your address input is insufficient.';
                    if (!inputAddress.city) {
                        msg += ' Please populate City';
                    } else if (!inputAddress.state) {
                        msg += ' Please populate State';
                    }
                    message.error(msg);
                } else {
                    this.setState({ openInvalid: true });
                }
            }).finally(() => this.setState({ validating: false }));
        } else {
            this.setState({ submitting: true });
            const { used } = this.props;
            const exceeded = this.getExceeded();
            const { reason: reasonType, additionalReason } = this.state;
            const reason = reasonType === 'Other' ? additionalReason : reasonType;
            submitOrder('', reason, used, inputAddress, fixedCI);
        }
    }

    render() {
        const {
            basketItems, showMessage, submitted, user
        } = this.props;
        const exceeded = this.getExceeded();
        const {
            inputAddress, validating, reason, additionalReason, contactInfo, showAddressCorrectedDialog,
            openInvalid, openRecommended, recommended, isPOBox, submitting
        } = this.state;

        const isAddressFilled = !!(inputAddress.firstName && inputAddress.lastName && inputAddress.address && inputAddress.email && inputAddress.zip);

        return (
            <Container fixed className="shop-cart-view">
                <Box component="div" mb={4}>
                    <Typography variant="h3">
                        CHECKOUT
                    </Typography>
                </Box>
                <Box>
                    {basketItems && basketItems.length > 0 && <BasketItemsView hasQty basketItems={basketItems} />}
                </Box>
                <Box mt={2} mb={2} p={2} component={Paper}>
                    <SingleAddressView
                        addr={inputAddress}
                        disableShippingMethod
                        onAddressChange={this.onInputAddressChange}
                        showMessage={showMessage}
                        isPOBox={isPOBox}
                        emailRequired
                    />
                </Box>
                {
                    basketItems && basketItems.length > 0 && basketItems[0].customFields && basketItems[0].customFields.originalOrderNumber && (
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="h7" style={{ float: 'right' }}>
                                    <b>Copied Order </b>{basketItems[0].customFields.originalOrderNumber}
                                </Typography>
                            </Box>
                        </Grid>
                    )
                }
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant="h6">
                                Contact Information
                            </Typography>
                        </Box>
                        <Grid container spacing={1} style={{ marginTop: '1rem' }} className="row-item">
                            <Grid item xs={12}>
                                <TextField
                                    label="Line 1"
                                    name="line1"
                                    fullWidth
                                    value={contactInfo ? contactInfo.line1 : ''}
                                    onChange={this.onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        maxLength: 45
                                    }}
                                    variant="outlined"
                                    error={contactInfo && contactInfo.line1 && contactInfo.line1.includes('|')}
                                    helperText={contactInfo && contactInfo.line1 && contactInfo.line1.includes('|') ? 'Invalid character |' : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Line 2"
                                    name="line2"
                                    fullWidth
                                    value={contactInfo ? contactInfo.line2 : ''}
                                    onChange={this.onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        maxLength: 45
                                    }}
                                    variant="outlined"
                                    error={contactInfo && contactInfo.line2 && contactInfo.line2.includes('|')}
                                    helperText={contactInfo && contactInfo.line2 && contactInfo.line2.includes('|') ? 'Invalid character |' : ''}
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={6}>
                        {exceeded && (
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Routing justification
                                    </Typography>
                                    <Typography variant="body1">
                                        Thank you for your interest in Aetna Medicare Products. Your order exceeds the monthly allocation limit for this item. During the checkout process please explain your need for additional supplies and your local sales team will review the order. Thank you!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined">
                                        <InputLabel>Select Reason *</InputLabel>

                                        <Select
                                            required
                                            value={reason}
                                            size="small"
                                            style={{ width: '15rem' }}
                                            label="Select Reason"
                                            onChange={(e) => this.onReasonChange(e.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>Select</em>
                                            </MenuItem>
                                            <MenuItem value="Community or large event">
                                                Community or large event
                                            </MenuItem>
                                            <MenuItem value="Replenish stock">
                                                Replenish stock
                                            </MenuItem>
                                            <MenuItem value="Initial stock not received">
                                                Initial stock not received
                                            </MenuItem>
                                            <MenuItem value="Other">
                                                Other...
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>
                                {reason === 'Other'
                                    && (
                                        <Grid item xs={12}>

                                            <TextField
                                                label="Please provide a justification to support the above reason(s)"
                                                multiline
                                                rows={4}
                                                fullWidth
                                                required
                                                value={additionalReason}
                                                onChange={this.changeAdditionalReason}
                                                variant="outlined"
                                            />
                                        </Grid>

                                    )}
                            </Grid>
                        )}
                    </Grid>

                </Grid>

                <Box>
                    <ButtonGroup className="list-btn-group">
                        <Button
                            className="btn place-order-btn"
                            disabled={(user && user.customFields && user.customFields.orderDisabled) || submitted || isPOBox || validating || submitting || !isAddressFilled || (exceeded && !reason) || (contactInfo && contactInfo.line1 && contactInfo.line1.includes('|')) || (contactInfo && contactInfo.line2 && contactInfo.line2.includes('|'))}
                            variant="contained"
                            color="primary"
                            onClick={this.onSubmitOrder}
                        >
                            Place Order
                        </Button>
                    </ButtonGroup>
                </Box>

                {
                    openRecommended && (
                        <RecommendedAddress
                            open={openRecommended}
                            onClose={() => { this.setState({ recommended: null, openRecommended: false }); }}
                            recommendedAddress={recommended}
                            originalAddress={inputAddress}
                            onSave={this.saveAddress}
                        />
                    )
                }
                {
                    openInvalid && (
                        <InvalidAddressDialog
                            open={openInvalid}
                            onClose={() => this.setState({ openInvalid: false })}
                            onSave={() => this.saveAddress(inputAddress, true)}
                        />
                    )
                }
                <Dialog open={showAddressCorrectedDialog} onClose={() => this.setState({ showAddressCorrectedDialog: false })} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Address Corrected</DialogTitle>
                    <DialogContent>
                        The address you entered has been corrected.
                    </DialogContent>
                </Dialog>
            </Container>
        );
    }
}
