import {
    Backdrop, Badge,
    Button, CircularProgress, Link, Paper,
    TableFooter, TextField
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import RootRef from '@material-ui/core/RootRef';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ToolTip from '@material-ui/core/ToolTip';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import CloudDownloadIcon from '@material-ui/icons/GetApp';
import { Alert } from '@material-ui/lab';
import message from 'components/Message';
import ProofingModal from 'components/ProofingModal';
import QuickviewModal from 'pages/catalog/QuickviewModal';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import http from 'utils/http';
import ConfirmPDFDownload from 'components/ConfirmPDFDownload';
import {
    getItemTypes, getKitItems, getP1BItems, getWHS, setKitItems, updateItems, getAJEPPItemTypes, getCounties, getInventoryItemTypes
} from '../action';
import EditFormModal from './edit-form-modal';
import { loadFormSchema } from './edit-form-modal/action';
import KitModal from './KitModal';
import OptionalItemPanel from './SelectItem/OptionalItemPanel';
import SuggestedItemPanel from './SelectItem/SuggestedItemPanel';
import SelectItemFileModal from './SelectItem/SelectItemFileModal';
import FindItemModal from './SelectItem/SelectItemModal';
import FindAJEPPItemModal from './SelectItem/SelectAJEPPItemModal';

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,

    ...(isDragging && {
        background: 'rgb(235,235,235)'
    })
});

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}));

const DenseCell = withStyles((theme) => ({
    body: {
        padding: '.2rem'
    }
}))(TableCell);

const SelectItemStep = (props) => {
    const [openCI, setOpenCI] = useState(false);
    const classes = useStyles();
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [openProofPreview, setOpenProofPreview] = useState(false);
    const [proofingItemId, setProofingItemId] = useState(null);
    const [previewPdf, setPreviewPdf] = useState(null);
    const [ciItemId, setCIItemId] = useState(null);
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');

    const [orderingOnHoldItem, setOrderingOnHoldItem] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openFileModal, setOpenFileModal] = useState(false);
    const [proofingItem, setProofingItem] = useState(null);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [init, setInit] = useState(false);

    const [openConfirm, setOpenConfirm] = useState(false);
    const [pdfDownloadUrl, setPdfDownloadUrl] = useState('');

    const {
        jobItems, attachments, loadFormSchema, lob: lobValue, jobType: jobTypeValue, screenData, attachmentSettings,
        user, getP1BItems, disableChild, state, disableAttachment, settings, spmLoaded, onlyState, isBackward, copyOrderData, whs
    } = props;
    let { jobType } = useParams();
    if (!jobType && props.jobType) {
        jobType = props.jobType;
    }
    let { lob } = useParams();
    if (!lob) lob = lobValue || 'OE';
    const needQty = jobType !== 'AJEKT' && jobType !== 'IM';
    const allowAttachment = lob !== 'p1a' && lob !== 'p1b' && !disableAttachment;
    const supportChild = !disableChild && user && !(user.customFields.userType === 'fsr' && lob === 'p1a') && lob !== 'p1b';
    const supportRequiredCode = user && user.customFields.userType !== 'p1a_fsr';

    const kitMustApprove = lob === 'p1b' || lob === 'p1a';
    const allowDownload = lob === 'p1a' && jobType === 'AJEPP' && (user.customFields.userType === 'fsr' || user.customFields.userType === 'su');
    const buildDownloadLink = (j) => {
        return `${process.env.REACT_APP_SERVER_URL}/download/p1a/${(j.isKit ? 'kit' : 'item')}/${j.id}?Authorization=${user.token}`.replace('/api/', '/file/');
        // if (j.isKit) {
        //     return `${process.env.REACT_APP_SERVER_URL}/download/${j.proofFileId}?Authorization=${user.token}`.replace('/api/', '/file/');
        // } else {
        //     return `${process.env.REACT_APP_SERVER_URL}/download/p1a/${j.id}?Authorization=${user.token}`.replace('/api/', '/file/');
        // }
    };
    const confirmDownload = (e, type, link) => {
        const kitRegEx = /^s\d\d/gi;
        if (type.match(kitRegEx)) {
            e.preventDefault();
            setPdfDownloadUrl(link);
            setOpenConfirm(true);
        }
    };
    const attachmentLimit = screenData && screenData.marketSegment && attachmentSettings
        ? attachmentSettings[screenData.marketSegment.toLowerCase()] || 1
        : 1;

    let marketSegment = '';
    if (lob === 'cml' && (jobType === 'AJEPP' || jobType === 'IM' || jobType === 'AJEKT')) {
        marketSegment = screenData.marketSegment;
    }

    const removeItem = (sequence, id) => {
        jobItems[sequence].quantity = '';
        for (let i = sequence; i < jobItems.length; i += 1) {
            if (jobItems[i].sequence > sequence) jobItems[i].sequence -= 1;
        }

        for (const ji of jobItems) {
            if (ji.relatedItems && ji.relatedItems.length > 0) {
                for (const ri of ji.relatedItems) {
                    if (ri.id === id) {
                        ri.selected = false;
                    }
                }
            }
        }

        if (jobItems[sequence].requiredCode) {
            jobItems[sequence].selected = false;
        } else {
            jobItems.splice(sequence, 1);
        }

        props.setKitItems([...jobItems]);
    };

    const startPreviewDocument = (id, code, paperSize, ci, held) => {
        if (lob === 'cml' || code.length >= 4) {
            startEditFormModal(id, code, paperSize, held);
        } else {
            composePodBook(id, ci);
        }
    };

    const startEditFormModal = (id, code, paperSize, held) => {
        loadFormSchema(code);
        setProofingItem({
            id, code, paperSize, held
        });
        setOpenFormModal(true);
    };

    const startPreviewHeldItem = (id) => {
        setGeneratingPdf(true);
        setProofingItemId(id);

        http.post(`direct-order/preview-hold-item/${lob}/${id}`)
            .then((dl) => {
                setPreviewPdf(dl);
                setOpenProofPreview(true);
            })
            .finally(() => setGeneratingPdf(false));
    };

    const composePodBook = (id, ci) => {
        setGeneratingPdf(true);
        setProofingItemId(id);

        http.post(`direct-order/preview-p1a-kit/${id}`, ci || { line1: '', line2: '' })
            .then((dl) => {
                setPreviewPdf(dl);
                setOpenProofPreview(true);
            })
            .finally(() => setGeneratingPdf(false));
    };

    const stopGenerating = () => {
        setGeneratingPdf(false);
        setProofingItemId(null);
        setOpenProofPreview(false);
    };
    const approvePdf = () => {
        const approvedItem = jobItems.find((ji) => ji.id === proofingItemId);
        approvedItem.approved = true;
        if (previewPdf.ids) {
            approvedItem.proofFileIds = previewPdf.ids;
        } else {
            approvedItem.proofFileId = previewPdf.id;
        }
        approvedItem.preview = previewPdf.preview;
        props.setKitItems([...jobItems]);
        setOpenFormModal(false);

        setProofingItemId(null);
        setOpenProofPreview(false);
    };
    const closeProof = () => {
        setPreviewPdf(null);
        setOpenProofPreview(false);
        setProofingItemId(null);
    };

    const onFormApproved = (formData, item) => {
        const approvedItem = jobItems.find((ji) => ji.id === item.id);
        approvedItem.approved = true;
        approvedItem.formData = formData;
        approvedItem.proofFileId = formData.proofFileId;
        props.setKitItems([...jobItems]);
        setOpenFormModal(false);
        setProofingItem(null);
    };

    const handleQtyChange = (seq, qty, max) => {
        // console.log('#ODS# ~ file: SelectItemStep.js ~ line 211 ~ handleQtyChange ~ max', max);
        if (qty === '') {
            jobItems[seq].quantity = '';
            props.setKitItems([...jobItems]);
            return;
        }
        // eslint-disable-next-line no-restricted-properties
        if (window.isNaN(qty)) return;
        let qtyInput = parseInt(qty, 10);
        if (qtyInput < 1) return;
        let limit = 10;

        if (jobType === 'AJEPP' && (lob === 'cml' || lob === 'p1b')) {
            limit = Number.MAX_VALUE;
        }

        if (jobType === 'OEJ' || jobType === 'INVENTORY') {
            limit = Number.MAX_VALUE;
        }

        if (max) limit = max;


        if (jobType === 'FJE') {
            limit = 10;
        }

        const isSU = user && user.customFields && user.customFields.userType === 'su';
        if (isSU) {
            limit = Number.MAX_VALUE;
        }

        if (jobType === 'FJE' && qtyInput > limit) {
            qtyInput = limit;
        }

        if (limit && qtyInput > limit) {
            qtyInput = limit;
        }

        jobItems[seq].quantity = qtyInput;
        props.setKitItems([...jobItems]);
    };

    const startCI = (itemId) => {
        const c = jobItems.find((ji) => ji.id === itemId);
        if (c && c.ci) {
            setLine1(c.ci.line1);
            setLine2(c.ci.line2);
        }
        setCIItemId(itemId);
        setOpenCI(true);
    };
    const onCIClose = () => {
        setCIItemId(null);
        setLine1('');
        setLine2('');
        setOpenCI(false);
    };
    const saveCI = (applyToAll) => {
        if (applyToAll) {
            for (const item of jobItems) {
                if (item.isKit) {
                    item.ci = { line1, line2 };
                    item.approved = false;
                }
            }
        } else {
            const item = jobItems.find((ji) => ji.id === ciItemId);
            item.ci = { line1, line2 };
            item.approved = false;
        }
        props.setKitItems([...jobItems]);
        onCIClose();
    };

    let yItems = [];
    let nItems = [];
    if (jobItems) {
        yItems = jobItems.filter((j) => j.selected);
        nItems = jobItems.filter((j) => !j.selected);
    }
    const missingEN = (lob === 'cml' && (jobType === 'AJEKT' || jobType === 'IM') && yItems && yItems.length > 0 && yItems.every((y) => y.type !== 'EN'));
    useEffect(() => {
        if (!init) {
            if (lob === 'cml' && (jobType === 'AJEKT' || jobType === 'IM') && !spmLoaded && !isBackward && !copyOrderData) {
                props.getKitItems();
            } else {
                // props.setKitItems([]);
            }
            if (lob === 'p1a' && jobType === 'AJEPP') {
                props.getAJEPPItemTypes(lob);
                props.getWHS(lob);
            } else if (lob) {
                if (jobType === 'INVENTORY')
                {
                    props.getInventoryItemTypes(lob);
                    props.getWHS(lob);
                } else {
                    const kitOnly = (lob === 'p1b' && (jobType === 'AJEKT' || jobType === 'IM'));
                    props.getItemTypes(lob, kitOnly);
                    props.getWHS(lob);
                }
                
            }
            setInit(true);
        }

        // if (isBackward) {
        //     return;
        // }

        const jis = jobItems || [];
        const selected = lob === 'p1b' ? jis : jis.filter((ji) => ji.selected);

        if (lob === 'p1a') {
            selected.forEach((ji) => {
                if (!ji.approved) {
                    ji.approved = ji.isPreorder && ji.code.startsWith(ji.preorderCode);
                }
            });
        }

        const hasSelectedItem = (selected.length > 0 || (lob === 'cml' && jobType === 'AJEPP' && attachments.length));
        if (!hasSelectedItem) {
            console.log('#ODS# ~ file: SelectItemStep.js ~ line 298 ~ useEffect ~ hasSelectedItem: ', hasSelectedItem);
            props.setIsNextReady(false);
            return;
        }
        const goodQty = selected.every((ji) => (!needQty || (ji.quantity && ji.quantity > 0)));
        if (!goodQty) {
            console.log('#ODS# ~ file: SelectItemStep.js ~ line 298 ~ useEffect ~ goodQty: ', goodQty);
            props.setIsNextReady(false);
            return;
        }
        const goodFormData = selected.every((ji) => ((!ji.vp || ji.vp === 'N' || ji.vp === 'PD') || !!ji.formData));
        if (!goodFormData) {
            console.log('#ODS# ~ file: SelectItemStep.js ~ line 298 ~ useEffect ~ goodFormData: ', goodFormData);
            props.setIsNextReady(false);
            return;
        }
        const allAttachmentsApproved = (!attachments || (attachments.length === 0 || attachments.every((a) => a.approved)));
        if (!allAttachmentsApproved) {
            console.log('#ODS# ~ file: SelectItemStep.js ~ line 298 ~ useEffect ~ allAttachmentsApproved: ', allAttachmentsApproved);
            props.setIsNextReady(false);
            return;
        }
        const allKitApproved = jobType === 'INVENTORY' || (!kitMustApprove || selected.every((s) => (!s.isKit && s.type !== 'TL') || s.approved));
        if (!allKitApproved) {
            console.log('#ODS# ~ file: SelectItemStep.js ~ line 298 ~ useEffect ~ allKitApproved: ', allKitApproved);
            props.setIsNextReady(false);
            return;
        }
        const allHeldApproved = jobType === 'INVENTORY' || (selected.every((s) => !s.held || s.approved));
        if (!allHeldApproved) {
            console.log('#ODS# ~ file: SelectItemStep.js ~ line 298 ~ useEffect ~ allHeldApproved: ', allHeldApproved);
            props.setIsNextReady(false);
            return;
        }
        const cmlSponsorApproved = (lob !== 'cml' || selected.every((s) => !settings || !settings.sponsorItemTypes.includes(s.type) || s.approved));
        if (!cmlSponsorApproved) {
            console.log('#ODS# ~ file: SelectItemStep.js ~ line 298 ~ useEffect ~ cmlSponsorApproved: ', cmlSponsorApproved);
            props.setIsNextReady(false);
            return;
        }
        if (missingEN) {
            console.log('#ODS# ~ file: SelectItemStep.js ~ line 298 ~ useEffect ~ missingEN: ', missingEN);
            props.setIsNextReady(false);
            return;
        }
        const nonBackordered = jobType === 'INVENTORY' || (
            (lob === 'cml' && jobType === 'AJEKT'
                && selected.some((item) => !(item.dp === 'N' && ((!item.inventoryQuantity || item.inventoryQuantity < 1) || (item.quantity > item.inventoryQuantity)) && item.disallowBackOrder)))
            || (selected.every((item) => !(item.dp === 'N' && ((!item.inventoryQuantity || item.inventoryQuantity < 1) || (item.quantity > item.inventoryQuantity)) && item.disallowBackOrder)))
        );
        if (!nonBackordered) {
            console.log('#ODS# ~ file: SelectItemStep.js ~ line 298 ~ useEffect ~ item.quantity > item.inventoryQuantity: ');
            props.setIsNextReady(false);
            return;

        }

        // const isValid = (selected.length > 0 || (lob === 'cml' && jobType === 'AJEPP' && attachments.length))
        //     && selected.every((ji) => (!needQty || (ji.quantity && ji.quantity > 0)) && ((!ji.vp || ji.vp === 'N' || ji.vp === 'PD') || !!ji.formData))
        //     && (!attachments || (attachments.length === 0 || attachments.every((a) => a.approved)))
        //     && (!kitMustApprove || selected.every((s) => !s.isKit || s.approved))
        //     && (selected.every((s) => !s.held || s.approved))
        //     && (lob !== 'cml' || selected.every((s) => !settings || !settings.sponsorItemTypes.includes(s.type) || s.approved))
        //     && !missingEN
        //     && (
        //         (lob === 'cml' && jobType === 'AJEKT'
        //             && selected.some((item) => !(item.dp === 'N' && ((!item.inventoryQuantity || item.inventoryQuantity < 1) || (item.quantity > item.inventoryQuantity)) && item.disallowBackOrder)))
        //         || (selected.every((item) => !(item.dp === 'N' && ((!item.inventoryQuantity || item.inventoryQuantity < 1) || (item.quantity > item.inventoryQuantity)) && item.disallowBackOrder)))
        //     );

        props.setIsNextReady(true);
    }, [attachments, init, jobItems, jobType, kitMustApprove, lob, needQty, props, settings, user, missingEN, spmLoaded, isBackward, copyOrderData]);

    const onDragEnd = (result) => {
        if (result.destination && result.destination.index !== result.source.index) {
            jobItems[result.source.index].sequence = result.destination.index;
            if (result.destination.index < result.source.index) {
                for (let i = result.destination.index; i < result.source.index; i += 1) {
                    jobItems[i].sequence += 1;
                    if (!jobItems[result.source.index].selected && jobItems[i].selected) {
                        jobItems[result.source.index].selected = true;
                    }
                }
            } else {
                for (let i = result.source.index + 1; i <= result.destination.index; i += 1) {
                    jobItems[i].sequence -= 1;
                }
                if (jobItems[result.source.index].selected) {
                    let allExcluded = true;
                    for (let i = result.destination.index; i < jobItems.length; i += 1) {
                        if (jobItems[i].selected) {
                            allExcluded = false;
                            break;
                        }
                    }
                    if (allExcluded) {
                        jobItems[result.source.index].selected = false;
                    }
                }
            }
            jobItems.sort((a, b) => a.sequence - b.sequence);
            props.setKitItems([...jobItems]);
        }
    };

    const addNItem = (id) => {
        jobItems.find((ji) => ji.id === id).selected = true;
        const selected = [];
        const n = [];
        for (let i = 0; i < jobItems.length; i += 1) {
            const ji = jobItems[i];
            if (ji.selected) {
                ji.sequence = selected.length;
                selected.push(ji);
            }
        }
        for (let i = 0; i < jobItems.length; i += 1) {
            const ji = jobItems[i];
            if (!ji.selected) {
                ji.sequence = selected.length + n.length;
                n.push(ji);
            }
        }
        props.setKitItems([...selected, ...n]);
    };

    const addSuggestedItem = (id, parentId) => {
        // console.log('#ODS# ~ file: SelectItemStep.js ~ line 443 ~ addRelatedItem ~ id: ', id);
        // console.log('#ODS# ~ file: SelectItemStep.js ~ line 443 ~ addRelatedItem ~ id: ', parentId);
        // console.log('#ODS# ~ file: SelectItemStep.js ~ line 444 ~ addRelatedItem ~ parentKit: ', jobItems.find((ji) => ji.id === parentId));
        const parentKit = jobItems.find((ji) => ji.id === parentId);
        const item = parentKit.relatedItems.find((i) => i.id === id);
        // console.log('#ODS# ~ file: SelectItemStep.js ~ line 444 ~ addRelatedItem ~ item: ', item);
        const added = jobItems.some((ji) => ji.id === id || (ji.code === item.code && ji.version === item.version));
        // console.log('#ODS# ~ file: SelectItemStep.js ~ line 444 ~ addRelatedItem ~ jobItems: ', jobItems);
        // console.log('#ODS# ~ file: SelectItemStep.js ~ line 444 ~ addRelatedItem ~ added: ', added);
        
        
        if (added) {
            message.info('Item exists in order. Please update quantity above.');

            const existingItem = jobItems.find((ji) => ji.id === id || (ji.code === item.code && ji.version === item.version));
            existingItem.quantity += 1;
            item.selected = true;

            props.setKitItems([...jobItems]);
        } else {
            item.selected = true;
            item.quantity = 1;
            item.fromSuggested = true;

            let lastSelectedIndex = 0;
            for (let i = 0; i < jobItems.length; i += 1) {
                if (jobItems[i].selected) { lastSelectedIndex = i; } else { break; }
            }
            jobItems.splice(lastSelectedIndex + 1, 0, item);

            let newItems = [...jobItems];

            newItems = newItems.sort((a, b) => {
                if (a.isKit > b.isKit) {
                    return 1;
                } else if (a.isKit < b.isKit) {
                    return -1;
                } else {
                    if (a.type > b.type) {
                        return 1;
                    } else if (a.type < b.type) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            });

            for (let i = 0; i < newItems.length; i += 1) {
                newItems[i].sequence = i;
            }

            props.setKitItems(newItems);
        }
    };

    const [showKitModal, setShowKitModal] = useState(false);
    const [showKitModalItem, setShowKitModalItem] = useState(null);
    const openKitModal = (id) => {
        const ji = jobItems.find((j) => j.id === id);
        setShowKitModalItem(ji);
        if (!ji.items) {
            getP1BItems(id);
        }
        setShowKitModal(true);
    };
    const closeKitModal = () => {
        setShowKitModal(false);
        setShowKitModalItem(null);
    };
    const supportKitModal = lob === 'p1b';

    const openItemPreview = (id, held) => {
        setQuickViewPdf(`${props.settings.quickviewRoot}/${lob}/${id}${(held ? '/true' : '')}?Authorization=${user.token}&ts=${(+new Date())}`);
        setOpenQuickView(true);
    };
    const [openQuickView, setOpenQuickView] = useState(false);
    const [quickViewPdf, setQuickViewPdf] = useState(null);

    // const allowToOrderSpecial = (user && user.customFields.userType === 'su'); // testing .

    const reviewProofItem = (item) => {
        setProofingItemId(item.id);
        setPreviewPdf({ preview: `${props.settings.quickviewRoot}/${lob}/${item.id}${(item.held ? '/true' : '')}?Authorization=${user.token}&ts=${(+new Date())}` });
        setOpenProofPreview(true);
    };

    const buildName = (item) => {
        if (item.type === 'TL') {
            return (
                <ToolTip title="Preview Item">
                    <Button
                        size="small"
                        style={{ color: (item.approved) ? 'green' : 'red' }}
                        onClick={() => reviewProofItem(item)}
                    >
                        <span>{item.name}</span>
                    </Button>
                </ToolTip>
            );

        }
        if (item.isKit || !item.hasResource) {
            return <span>{item.name}</span>;
        }

        if (item.held) { // startPreviewHeldItem
            return (
                <ToolTip title="Preview Item">
                    <Button
                        size="small"
                        style={{ color: (item.approved) ? 'green' : 'red' }}
                        onClick={() => startPreviewHeldItem(item.id)}
                    >
                        <span>{item.name}</span>
                    </Button>
                </ToolTip>
            );
        }

        if ((lob === 'cml' && settings.sponsorItemTypes.includes(item.type) && !item.approved)) {
            return (
                <ToolTip title="Preview Item">
                    <Button
                        size="small"
                        style={{ color: (item.approved) ? 'green' : 'red' }}
                        onClick={() => reviewProofItem(item)}
                    >
                        <span>{item.name}</span>
                    </Button>
                </ToolTip>
            );
        }

        return (
            <ToolTip title="Preview Item">
                <Button
                    size="small"
                    onClick={() => openItemPreview(item.id, item.held)}
                    style={{ color: (item.held && !item.approved) ? 'red' : '' }}
                >
                    <span>{item.name}</span>
                </Button>
            </ToolTip>
        );
    };

    const filterValidAttachment = (aaa) => {
        var b = aaa.filter(a => {
            if (!a.approved) return false;
            if (typeof a.apnCode !== 'undefined' && (!a.apnCode || !a.docType)) return false;

            return true;
        });
        return b.length;
    };

    const disableEF = lob === 'p1b' && (jobType === 'AJEKT' || jobType === 'IM')
        && (user.customFields.userType === 'su' || user.customFields.userType === 'fsr')
        && (yItems.some(i => i.kitTypeName === 'STANDARD') && yItems.some(i => i.kitTypeName === 'SILVERSCRIPT'));

    const calcItemInventoryValue = (item) => {
        return item.dp === 'Y' || ((lob === 'p1a' || lob === 'p1b') && item.isKit)
            ? 'N/A'
            : (item.inventoryQuantity !== undefined ? Math.max(0, item.inventoryQuantity) : '');
    }

    let exceededItems = '';
    if (jobType === 'AJEPP' || jobType === 'AJEKT') {
        exceededItems = yItems.filter(item => {
            if (item.dp !== 'N') return false;

            if (lob === 'p1a' && jobType === 'AJEPP') return false;

            if ((lob === 'p1a' || lob === 'p1b') && item.isKit) {
                return false;
            }

            if (!item.inventoryQuantity || item.quantity > item.inventoryQuantity) {
                return true;
            }
            return false;
        }).map(item => item.code).join(', ');
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(dropProvided) => (
                        <RootRef rootRef={dropProvided.innerRef}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label=" table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ padding: '.5rem' }}>Item Code</TableCell>
                                            <TableCell style={{ padding: '.5rem' }}>Version</TableCell>
                                            {!(lob === 'p1a' && jobType === 'AJEPP') && (<TableCell style={{ padding: '.5rem' }}>WHS</TableCell>)}
                                            {!(lob === 'p1a' && jobType === 'AJEPP') && (<TableCell style={{ padding: '.5rem' }}>INV</TableCell>)}
                                            <TableCell style={{ padding: '.5rem' }}>Name</TableCell>
                                            {
                                                jobType !== 'INVENTORY' && (
                                                    <>
                                                        <TableCell style={{ padding: '.5rem', textAlign: 'center' }}>CI</TableCell>
                                                        <TableCell style={{ padding: '.5rem' }}>VP</TableCell>
                                                    </>
                                                )
                                            }
                                            {!(lob === 'p1a' && jobType === 'AJEPP') && (<TableCell style={{ padding: '.5rem' }}>DP</TableCell>)}
                                            <TableCell style={{ padding: '.5rem' }}>TYPE</TableCell>
                                            {supportChild && !(lob === 'p1a' && jobType === 'AJEPP') && <TableCell style={{ padding: '.5rem' }}>Child</TableCell>}
                                            {allowDownload && <TableCell style={{ padding: '.5rem' }} />}
                                            {(jobType === 'AJEKT' || (jobType === 'IM' && supportRequiredCode))
                                                && lob !== 'p1b'
                                                && (<TableCell style={{ padding: '.5rem' }}>Default Item</TableCell>)}
                                            <TableCell style={{ padding: '.5rem' }}>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => {
                                                        setOrderingOnHoldItem(false);
                                                        setOpenModal(true);
                                                    }}
                                                >
                                                    Select Items
                                                </Button>
                                                {allowAttachment && (
                                                    <IconButton
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => setOpenFileModal(true)}
                                                    >
                                                        {attachments && attachments.length > 0 && (
                                                            <Badge badgeContent={`${filterValidAttachment(attachments, jobType)}/${attachments.length}`} color="primary">
                                                                <AttachFileIcon />
                                                            </Badge>
                                                        )}
                                                        {attachments && attachments.length === 0 && (
                                                            <AttachFileIcon />
                                                        )}
                                                    </IconButton>
                                                )}
                                                {/* {allowToOrderSpecial && (
                                                    <ToolTip title="Select HOLD Item">
                                                        <IconButton
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => {
                                                                setOrderingOnHoldItem(true);
                                                                setOpenModal(true);
                                                            }}
                                                        >
                                                            <AddCircleOutlineIcon />
                                                        </IconButton>
                                                    </ToolTip>
                                                )} */}

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {yItems.map((item, index) => (

                                            <Draggable key={item.id} draggableId={item.id + item.code} index={index}>
                                                {(dragProvided, snapshot) => (
                                                    <>
                                                        <TableRow
                                                            key={item.id}
                                                            ref={dragProvided.innerRef}
                                                            {...dragProvided.draggableProps}
                                                            {...dragProvided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                dragProvided.draggableProps.style
                                                            )}
                                                        >
                                                            <TableCell style={{ padding: '.5rem' }}>
                                                                {supportKitModal && (item.isKit)
                                                                    ? (
                                                                        <Link
                                                                            component="button"
                                                                            variant="body2"
                                                                            style={{ color: (kitMustApprove && !item.approved) ? 'red' : 'green' }}
                                                                            onClick={() => openKitModal(item.id)}
                                                                        >
                                                                            {item.code}
                                                                        </Link>
                                                                    )
                                                                    : lob === 'p1a' && jobType === 'AJEPP' && item.isKit ? (<span><b>{item.code}</b></span>) : (<span>{item.code}</span>)}
                                                            </TableCell>
                                                            <TableCell style={{ padding: '.5rem' }}>{item.version}</TableCell>
                                                            {!(lob === 'p1a' && jobType === 'AJEPP') && (<TableCell style={{ padding: '.5rem' }}>{jobType === 'IM' ? '' : item.whs}</TableCell>)}
                                                            {!(lob === 'p1a' && jobType === 'AJEPP') && (
                                                                <DenseCell
                                                                    style={{ color: (item.dp === 'N' && ((!item.inventoryQuantity || item.inventoryQuantity < 1) || item.quantity > item.inventoryQuantity) && item.disallowBackOrder ? 'red' : '') }}
                                                                >{calcItemInventoryValue(item)}
                                                                </DenseCell>
                                                            )}
                                                            <TableCell style={{ padding: '.5rem' }}>
                                                                {buildName(item)}
                                                            </TableCell>

                                                            {
                                                                jobType !== 'INVENTORY' && (
                                                                    <>
                                                                        <TableCell style={{ padding: '.5rem', textAlign: 'center' }}>
                                                                            {item.isKit && (
                                                                                <Button size="small" color="secondary" onClick={() => startCI(item.id)}>
                                                                                    <span style={{ color: (item.ci ? 'blue' : 'brown') }}><b>CI</b></span>
                                                                                </Button>

                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell style={{ padding: '.5rem' }}>
                                                                            {item.vp === 'N'
                                                                                ? (!(lob === 'p1a' && jobType === 'AJEPP') ? 'N' : '')
                                                                                : (
                                                                                    <Link
                                                                                        component="button"
                                                                                        variant="body2"
                                                                                        style={{ color: (item.approved || (!!item.isPreorder && item.code.startsWith(item.preorderCode)) ? 'green' : 'red') }}
                                                                                        onClick={() => {
                                                                                            startPreviewDocument(item.id, item.vp, item.paperSize, item.ci, item.held);
                                                                                        }}
                                                                                    >
                                                                                        {item.vp}
                                                                                    </Link>
                                                                                )}

                                                                        </TableCell>
                                                                    </>
                                                                )
                                                            }
                                                            {!(lob === 'p1a' && jobType === 'AJEPP') && <TableCell style={{ padding: '.5rem' }}> {item.dp}</TableCell>}
                                                            <TableCell style={{ padding: '.5rem' }}>{item.type}</TableCell>

                                                            {supportChild && !(lob === 'p1a' && jobType === 'AJEPP') && <TableCell style={{ padding: '.5rem' }} />}
                                                            {allowDownload && (
                                                                <TableCell style={{ padding: '.5rem' }}>
                                                                    {(item.isDownload && (!item.isKit || (item.isKit && item.approved))) && (
                                                                        // <Button
                                                                        //     // href={buildDownloadLink(item)}
                                                                        //     target="_blank"
                                                                        //     color="primary"
                                                                        //     onClick={() => confirmDownload(item)}
                                                                        // >
                                                                        //     <CloudDownloadIcon color="primary" size="small" />
                                                                        // </Button>
                                                                        <Link
                                                                            href={buildDownloadLink(item)}
                                                                            target="_blank"
                                                                            color="primary"
                                                                            onClick={(e) => confirmDownload(e, item.type, buildDownloadLink(item))}
                                                                        >
                                                                            <CloudDownloadIcon color="primary" size="small" />
                                                                        </Link>
                                                                    )}
                                                                </TableCell>
                                                            )}

                                                            {(jobType === 'AJEKT' || (jobType === 'IM' && supportRequiredCode))
                                                                && lob !== 'p1b'
                                                                && <TableCell style={{ padding: '.5rem' }}>{item.requiredCode}</TableCell>}
                                                            <TableCell style={{ padding: '.5rem' }}>
                                                                {needQty
                                                                    && (
                                                                        <TextField
                                                                            style={{ width: '2rem', textAlign: 'center' }}
                                                                            size="small"
                                                                            display="inline"
                                                                            error={item.dp === 'N' && ((!item.inventoryQuantity || item.inventoryQuantity < 1) || item.quantity > item.inventoryQuantity) && item.disallowBackOrder}
                                                                            type="text"
                                                                            value={item.quantity}
                                                                            onChange={(e) => handleQtyChange(item.sequence, e.target.value, item.max || item.qtyLimit)}
                                                                        />
                                                                    )}
                                                                {item.requiredCode !== 'R'
                                                                    && (
                                                                        <IconButton
                                                                            size="small"
                                                                            aria-label="delete"
                                                                            display="inline"
                                                                            m={1}
                                                                            onClick={() => removeItem(item.sequence || index, item.id)}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    )}

                                                                <IconButton
                                                                    size="small"
                                                                    aria-label="delete"
                                                                    display="inline"
                                                                    style={{ cursor: 'grab' }}
                                                                    m={1}
                                                                >
                                                                    <DragHandleIcon />
                                                                </IconButton>
                                                            </TableCell>

                                                        </TableRow>

                                                        {item.children && item.children.length > 0 && item.children.map((ic) => (
                                                            <TableRow
                                                                key={ic.id}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    dragProvided.draggableProps.style
                                                                )}
                                                            >
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.code}</TableCell>
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.version}</TableCell>
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.whs}</TableCell>
                                                                <DenseCell>{ic.dp === 'Y' || ((lob === 'p1a' || lob === 'p1b') && item.isKit) ? 'N/A' : (ic.inventoryQuantity ? Math.max(0, ic.inventoryQuantity) : '')}</DenseCell>
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.name}</TableCell>
                                                                {
                                                                    jobType !== 'INVENTORY' && (
                                                                        <>
                                                                            <TableCell style={{ padding: '.5rem' }} />
                                                                            <TableCell style={{ padding: '.5rem' }}>{ic.vp}</TableCell>
                                                                        </>
                                                                    )
                                                                }
                                                                <TableCell style={{ padding: '.5rem' }}> {ic.dp}</TableCell>
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.type}</TableCell>

                                                                {supportChild && <TableCell style={{ padding: '.5rem' }}>Y</TableCell>}
                                                                {(jobType === 'AJEKT' || (jobType === 'IM' && supportRequiredCode))
                                                                    && lob !== 'p1b'
                                                                    && <TableCell style={{ padding: '.5rem' }}>{item.requiredCode}</TableCell>}
                                                                <TableCell style={{ padding: '.5rem' }} />

                                                            </TableRow>

                                                        ))}

                                                        {item.relatedItems && item.relatedItems.length > 0 && (
                                                            <TableRow>
                                                                <TableCell style={{ padding: '.5rem' }} colSpan={12}>
                                                                    <SuggestedItemPanel jobType={jobType} lob={lob} parentId={item.id} suggestedItems={item.relatedItems} addSuggestedItem={addSuggestedItem} disabled={item.relatedItems.every((i) => i.selected)} />
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </>
                                                )}
                                            </Draggable>

                                        ))}
                                        {dropProvided.placeholder}
                                        {
                                            exceededItems && (
                                                <TableRow>
                                                    <TableCell colSpan={20}>
                                                        <Alert severity="warning">
                                                            Order quantity for item {exceededItems} exceeds the available quantity. Please note item will be on back order and shipped later when quantity is available
                                                        </Alert>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                    {missingEN
                                        && (
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan={20}>
                                                        <Alert severity="error">Envelope (EN) is required.</Alert>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                </Table>
                            </TableContainer>
                        </RootRef>
                    )}
                </Droppable>
            </DragDropContext>
            {
                nItems.length > 0 && <OptionalItemPanel jobType={jobType} lob={lob} nItems={nItems} addNItem={addNItem} />
            }
            <FindItemModal
                open={!(lob === 'p1a' && jobType === 'AJEPP') && openModal}
                needQty={needQty}
                onlyWhs={jobType === 'INVENTORY' ? whs : props.onlyWhs}
                onlyState={props.onlyState}
                lob={lobValue}
                jobType={jobTypeValue}
                p1afsrLimit={user.customFields.p1afsrLimit}
                userType={user.customFields.userType}
                msoFJEJobItemLimit={user.customFields.msoFJEJobItemLimit}
                orderingOnHoldItem={orderingOnHoldItem}
                state={state}
                marketSegment={marketSegment}
                handleClose={() => { setOrderingOnHoldItem(false); setOpenModal(false); }}
            />
            <FindAJEPPItemModal
                open={lob === 'p1a' && jobType === 'AJEPP' && openModal}
                needQty={needQty}
                onlyWhs={jobType === 'INVENTORY' ? whs : props.onlyWhs}
                onlyState={props.onlyState}
                lob={lobValue}
                jobType={jobTypeValue}
                p1afsrLimit={user.customFields.p1afsrLimit}
                userType={user.customFields.userType}
                msoFJEJobItemLimit={user.customFields.msoFJEJobItemLimit}
                orderingOnHoldItem={orderingOnHoldItem}
                state={state}
                marketSegment={marketSegment}
                handleClose={() => { setOrderingOnHoldItem(false); setOpenModal(false); }}
                getCounties={props.getCounties}
                allowDownload={allowDownload}
                confirmDownload={confirmDownload}
                user={user}
            />
            <SelectItemFileModal
                open={openFileModal}
                disableItemType={props.disableItemType || lob === 'cml'}
                handleClose={() => setOpenFileModal(false)}
                lob={lobValue}
                jobType={jobTypeValue}
                user={user}
                attachmentLimit={props.shellLimit || attachmentLimit}
            />
            <EditFormModal
                open={openFormModal}
                proofingItem={proofingItem}
                onApproved={onFormApproved}
                handleClose={() => { setOpenFormModal(false); setProofingItem(null); }}
            />
            <KitModal
                open={showKitModal}
                kit={showKitModalItem}
                handleClose={closeKitModal}
                lob={lob}
                jobType={jobType}
                disableEF={disableEF}
            />
            <Dialog open={openCI} onClose={onCIClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Contact Information</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Line 1"
                        type="text"
                        fullWidth

                        value={line1}
                        inputProps={{
                            maxLength: 45
                        }}
                        onChange={(e) => setLine1(e.target.value)}
                        error={line1 && line1.includes('|')}
                        helperText={line1 && line1.includes('|') ? 'Invalid character |' : ''}
                    />
                    <TextField
                        margin="dense"
                        label="Line 2"
                        type="text"
                        fullWidth

                        value={line2}
                        inputProps={{
                            maxLength: 45
                        }}
                        onChange={(e) => setLine2(e.target.value)}
                        error={line2 && line2.includes('|')}
                        helperText={line2 && line2.includes('|') ? 'Invalid character |' : ''}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onCIClose()} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => saveCI()}
                        color="primary"
                        disabled={
                            (line1 && line1.includes('|'))
                            || (line2 && line2.includes('|'))
                        }
                    >
                        Update
                    </Button>
                    <Button
                        onClick={() => saveCI(true)}
                        color="primary"
                        disabled={
                            (line1 && line1.includes('|'))
                            || (line2 && line2.includes('|'))
                        }
                    >
                        Copy All
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmPDFDownload
                open={openConfirm}
                onClose={() => { setOpenConfirm(false); }}
                setOpenConfirm={setOpenConfirm}
                pdfDownloadUrl={pdfDownloadUrl}
                setPdfDownloadUrl={setPdfDownloadUrl}
            />

            <Backdrop className={classes.backdrop} open={generatingPdf} onClick={stopGenerating}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <ProofingModal
                open={openProofPreview}
                pdf={previewPdf && previewPdf.preview}
                onApprove={approvePdf}
                onCancel={closeProof}
            />

            {openQuickView && (
                <QuickviewModal
                    open={openQuickView}
                    closeQuickview={() => { setOpenQuickView(false); setQuickViewPdf(null); }}
                    quickviewPDF={quickViewPdf}
                />
            )}
        </>
    );
};

const mapStateToProps = ({
    directOrderView: {
        stepView: {
            jobItems, attachments, attachmentSettings, screenData, addresses,
            spmLoaded, copyOrderData
        }
    },
    appView: {
        user, settings
    }
}) => ({
    jobItems, attachments, attachmentSettings, screenData, user, settings,
    state: (addresses && addresses.length > 0 ? addresses[0].state : ''),
    whs: (addresses && addresses.length > 0 ? addresses[0].whs : ''),
    spmLoaded, copyOrderData
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getKitItems, updateItems, setKitItems, loadFormSchema, getItemTypes, getP1BItems, getWHS, getAJEPPItemTypes, getCounties, getInventoryItemTypes
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectItemStep);
