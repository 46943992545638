import {
    Box, Button,

    Collapse, IconButton,
    Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import history from 'utils/history';

export default function OrderRow(props) {
    const {
        row, hasCustomerName, onViewClick, onCopyClick, showKitCount, showRecipient
    } = props;
    const [open, setOpen] = React.useState(false);

    const toFullName = (s) => {
        switch (s) {
            case 'FJE':
                return 'Fast Job';
            case 'AJEPP':
                return 'Pick & Pack';
            case 'AJEKT':
                return 'Kit Job';
            case 'IM':
                return 'IM';
            case 'INVENTORY':
                return 'INVENTORY';
            case 'Broker':
                return 'Broker';
            case 'telesales':
                return 'Telesales'
            default:
                return '';
        }
    };

    const onView = (id) => {
        if (onViewClick) onViewClick(id);
        else history.push(`/order/${row.id}`);
    };

    const toLocal = (orderedDate) => {
        const d = new Date(orderedDate);
        return `${d.toLocaleDateString().replace('202', '2')} ${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}`;
    };

    return (
        <>
            <TableRow>
                <TableCell style={{ padding: '.5rem' }}>
                    {row.shippingInfo && row.shippingInfo.length > 0 && (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell style={{ padding: '.5rem' }} component="th" scope="row">
                    {row.orderNumber}
                    <Box style={{ marginTop: '.5rem', color: '#555', fontSize: '.8rem' }}>Order Date: {toLocal(row.orderedDate)}</Box>
                    {
                        row.program === 'Medicare Group' && row.ddd && (
                            <Box style={{ marginTop: '.5rem', color: '#555', fontSize: '.8rem' }}>
                                {row.orderStatus.includes('Shipped') ? 'Actual Ship Date' : 'Estimated Ship Date'}: {row.ddd}
                            </Box>
                        )
                    }
                    {
                        row.program !== 'Medicare Group' && !(row.jobType === 'telesales' || row.jobType === 'Broker' || row.jobType === 'FJE' || row.jobType === 'AJEPP') && row.ddd && (
                            <Box style={{ marginTop: '.5rem', color: '#555', fontSize: '.8rem' }}>
                                {row.jobType === 'FJE' || row.jobType === 'IM' ? 'Ship Date' : 'Delivery Date'}: {row.ddd}
                            </Box>
                        )
                    }
                </TableCell>
                <TableCell style={{ padding: '.5rem' }}>{row.orderedBy}</TableCell>
                {
                    showRecipient && (
                        <TableCell style={{ padding: '.5rem' }}>{row.recipients?.join(', ')}</TableCell>
                    )
                }
                <TableCell style={{ padding: '.5rem' }}>{row.program}</TableCell>
                <TableCell style={{ padding: '.5rem' }}>
                    {toFullName(row.jobType)}
                </TableCell>
                <TableCell style={{ padding: '.5rem' }}>{row.marketSegment}</TableCell>
                <TableCell style={{ padding: '.5rem' }}>{row.costCenter}</TableCell>
                {
                    hasCustomerName && (<TableCell><span style={{ fontSize: '.8rem' }}>{row.customerName}</span></TableCell>)
                }
                {showKitCount && (
                    <TableCell style={{ padding: '.5rem', textAlign: 'center' }}>
                        {
                            row.jobType === 'IM' || row.jobType === 'AJEKT'
                                ? row.kitCount
                                : null
                        }
                    </TableCell>
                )}
                <TableCell style={{ padding: '.5rem' }}>{row.orderStatus}</TableCell>
                <TableCell style={{ padding: '.5rem' }}>{row.whs}</TableCell>
                <TableCell style={{ padding: '.5rem' }}>{row.fileNumber}</TableCell>
                <TableCell style={{ padding: '.5rem', width: '85px' }} align="center">
                    <Button onClick={() => onView(row.id)} color="primary">
                        <VisibilityIcon /> View
                    </Button>
                    {onCopyClick && (
                        <Button onClick={() => onCopyClick(row.id)} color="primary">
                            <LibraryAddIcon /> Copy
                        </Button>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Shipping
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tracking Number</TableCell>
                                        <TableCell>Shipped Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.shippingInfo && row.shippingInfo.map((shipInfo, index) => (
                                        <TableRow key={`${shipInfo.shippedDate}_${index}`}>
                                            <TableCell component="th" scope="row">
                                                {shipInfo.trackings.map(t => (
                                                    t.trackingLink
                                                        ? <a target="_blank" href={t.trackingLink} style={{ marginRight: '.5rem' }}>{t.trackingNumber}</a>
                                                        : <span style={{ marginRight: '.5rem' }}>{t.trackingNumber}</span>
                                                ))
                                                }
                                            </TableCell>
                                            <TableCell>{shipInfo.shippedDate}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
